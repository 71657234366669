import React, { useEffect } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import styled from 'styled-components';
import Layout from '../components/layout';
import Media from 'react-media';
import PageHeader from '../components/pageHeader';
import Hero from '../assets/Centrik/centrik-hero.svg';
import OperationsIcon from '../assets/Centrik/centrik-icon-operations.svg';
import DocumentControlIcon from '../assets/Centrik/centrik-icon-duplications.svg';
import DuplicationIcon from '../assets/Centrik/centrik-icon-reduce-duplication.svg';
import ComplianceIcon from '../assets/Centrik/centrik-icon-regulatory-compliance.svg';
import WorkflowsIcon from '../assets/Centrik/centrik-icon-workflows.svg';
import CommunicationIcon from '../assets/Centrik/centrik-icon-enhance-communication.svg';
import TimeManagementIcon from '../assets/Centrik/centrik-icon-real-time-management.svg';
import SafeOrganisationIcon from '../assets/Centrik/centrik-icon-safer-organisation.svg';
import Logo from '../assets/Centrik/centrik-logo.svg';
import CloudBased from '../assets/Centrik/centrik-cloud-based.svg';
import Implementation from '../assets/Centrik/centrik-implementation.svg';
import CentrikWhy from '../assets/Centrik/centrik-why.svg';
import Tick from '../assets/icon-tick.svg';
import ASSILogo from '../assets/Centrik/assi-logo.svg';
import ScrollAnimation from "react-animate-on-scroll";
import pageStyles from "./page.module.less";
import styles from '../pages/taskcards.module.less'
import SEO from '../components/SEO';
import GlobalScale from '../components/LandingPage/globalScale';
import CentrikInfoSection from '../components/Centrik/CentrikInfoSection';
import GoArrow from "../assets/go-arrow-blue.svg";
import PortfolioCards from '../components/Centrik/PortfolioCards';
import buttonStyles from '../components/Layout/started.module.less';
import getWindowSize from '../components/getWindowSize';

const TopCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 16px;
    color: #242d41;
    letter-spacing: 0.02px;
    display: inline-block;
    margin-top: 15px;
  }
  img {
    height: 90px;
    width: 90px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`;

const Paperless = styled.span`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  padding: 0 10px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
  }
`;

const Touch = styled.span`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.02px;
  color: #1E5BB5;
  padding: 0 8px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const StyledArrow = styled.img`
  height: 14px;
`;

const Title = styled.h3`
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 20px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: rgba(36, 45, 65, 0.9);
  margin-right: 20%;
  margin-left: 20%;
  @media screen and (max-width: 450px) {
    margin: 0;
  }
`;

const ImageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ right }) => (right ? 'flex-start' : 'flex-end')};
  padding-right: ${({ padding }) => (padding ? '0' : '25px')};
  padding-left: ${({ padding }) => (padding ? '25px' : '0')};
`;

const TextDiv = styled.div`
  margin-left: ${({ right, none }) => {
    if (right) return '125px';
    if (none) return '0';
    return '25px';
  }};
  @media screen and (max-width: 450px) {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

const TitleWithImage = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: #242d41;
  margin-bottom: 40px;
`;

const TickDiv = styled.div`
  margin-bottom: 20px;
  width: 480px;
  display: flex;
  div {
    font-size: 16px;
    line-height: 1.57;
    color: rgba(36, 45, 65, 0.7);
  }
  @media screen and (max-width: 1025px) {
    width: auto;
  }
`;

const StyledTick = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const CAADiv = styled.div`
  display: flex;
  margin-top: 67px;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const CAATextWrapper = styled.div`
  padding-top: 43px;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.7);
`;

const CAAInnerText = styled.div`
  margin-bottom: 28px;
`;

const BoldText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 4px;
`;

const Gradient = styled.div`
  background-image: linear-gradient(to top, #f8fcff, #fff);
  padding: ${({ less }) => (less ? '120px 200px 75px' : '120px 200px')};
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    padding: 120px 30px;
  }
  @media screen and (max-width: 800px) {
    padding: 120px 30px;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 10px;
  }
`;

const Centrik = ({ data }) => {

  const { width, height } = getWindowSize();
  console.log(width, height);

  const phoneScreen = width < 450;
  const landscapeIpad = width < 1025 && height < 770;
  const iPadScreen = width < 800;
  
  return (
    <Layout>
      <SEO
        title="Fully integrated, real-time operational management"
        description="Everything you need to manage your operation, at your fingertips."
      />
      <PageHeader
        header="Fully integrated, real-time operational management"
        subHeader="Everything you need to manage your operation, at your fingertips."
        linkText="Schedule a demo"
        bigHeader
        bigImage={Hero}
        imageWithShadow
        link="/contact?demo"
        whiteLink
        logo={Logo}
        centrik
        svgImage
      />
      <Gradient less>
        <Container>
          <Row
            className={`justify-content-md-center`}
          >
            <Col md={12}>
              <Row>
                <Col>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <Title>Centrik will help you</Title>
                  </ScrollAnimation>
                </Col>
              </Row>
              <Row className={styles.topCardsParent}>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                    <TopCardWrapper>
                      <img src={OperationsIcon} alt="records" />
                      <span>
                        Gain real-time <br />
                        operational oversight
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                    <TopCardWrapper>
                      <img src={DocumentControlIcon} alt="productivity" />
                      <span>
                        Deliver total document <br />
                        control
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                    <TopCardWrapper>
                      <img src={DuplicationIcon} alt="investment" />
                      <span>
                        Reduce duplication
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                    <TopCardWrapper>
                      <img src={ComplianceIcon} alt="investment" />
                      <span>
                        Ensure regulatory <br />
                        compliance
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
              </Row>
              <Row className={`${styles.topCardsParent} justify-content-md-center`}>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                    <TopCardWrapper>
                      <img src={WorkflowsIcon} alt="records" />
                      <span>
                        Improve workflows
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                    <TopCardWrapper>
                      <img src={CommunicationIcon} alt="productivity" />
                      <span>
                        Enhance communication
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                    <TopCardWrapper>
                      <img src={TimeManagementIcon} alt="investment" />
                      <span>
                        Achieve real-time integrated management
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
                <Col sm={3}>
                  <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                    <TopCardWrapper>
                      <img src={SafeOrganisationIcon} alt="investment" />
                      <span>
                        Enhance safety culture
                      </span>
                    </TopCardWrapper>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Gradient>
      <Container>
        <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
          <CentrikInfoSection phoneScreen={phoneScreen} />
        </ScrollAnimation>
      </Container>
      <ScrollAnimation
        animateOnce
        animateIn='fadeIn'
        offset={0}
        delay={300}
      >
        <GlobalScale centrik />
      </ScrollAnimation>
      <Container>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`} style={{ padding: phoneScreen || landscapeIpad ? '0 2%' : '0 10%', marginTop: phoneScreen && '80px' }}>
          <Col md={12} lg={6} style={{ padding: phoneScreen && '0' }}>
            <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <TextDiv none>
                <TitleWithImage>Why Centrik?</TitleWithImage>
                <TickDiv>
                  <div>Centrik evolved in the uncompromising world of commercial aviation. Now we offer a cutting-edge solution that drives efficiencies, raises standards, and increases accountability.</div>
                </TickDiv>
                <TickDiv>
                  <div>With Centrik you can achieve comprehensive, real-time oversight across departments operating nationally or internationally.</div>
                </TickDiv>
                <TickDiv>
                  <div>Our system is constantly evolving and improving. We work closely with our existing customers and regulatory bodies from around the world, liaising with high-ranking industry figures to ensure that we remain world leaders in aviation.</div>
                </TickDiv>
              </TextDiv>
            </ScrollAnimation>
          </Col>
          <Col md={12} lg={6} style={{ marginTop: phoneScreen && '80px' }}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <ImageDiv right padding>
                <img src={CentrikWhy} alt="image" />
              </ImageDiv>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`}  style={{ padding: phoneScreen || landscapeIpad ? '0 2%' : '0 10%', marginTop: phoneScreen && '80px' }}>
          {!iPadScreen && (
            <Col md={12} lg={6}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <ImageDiv right>
                  <img src={CloudBased} alt="image" />
                </ImageDiv>
              </ScrollAnimation>
            </Col>
          )}
          <Col md={12} lg={6} style={{ padding: phoneScreen && '0' }}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <TextDiv>
                <TitleWithImage>Centrik is cloud-based & paperless</TitleWithImage>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>Access or record information remotely, on any device, anywhere, any time</div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>No more chasing email trails or paper copies</div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>Personal dashboards provide complete transparency and accountability</div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>Managers have complete, real-time oversight of the entire operation</div>
                </TickDiv>
                <TickDiv>
                  <StyledTick src={Tick} alt="tick" />
                  <div>Centrik information is true, complete and accessible 24/7</div>
                </TickDiv>
              </TextDiv>
            </ScrollAnimation>
          </Col>
          {iPadScreen && (
            <Col md={12} lg={6} style={{ marginTop: phoneScreen && '80px' }}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <ImageDiv right>
                  <img src={CloudBased} alt="image" />
                </ImageDiv>
              </ScrollAnimation>
            </Col>
          )}
        </Row>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`} style={{ padding: phoneScreen || landscapeIpad ? '0 2%' : '0 10%', marginTop: phoneScreen ? '80px' : '140px' }}>
          <Col md={12} lg={6} style={{ padding: phoneScreen && '0' }}>
            <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <TextDiv>
                <TitleWithImage>Centrik implementation is easy</TitleWithImage>
                <TickDiv>
                  <div>Your system set-up is managed by a dedicated Implementation Executive, who will identify your particular needs and configure Centrik to meet them.</div>
                </TickDiv>
                <TickDiv>
                  <div>All training and data transition is handled by our experts, with full 24/7 support available from the moment we start.</div>
                </TickDiv>
                <TickDiv>
                  <div>All TrustFlight platforms are continually assessed and updated by our software engineers, ensuring we can adapt to the ever-changing demands of your business, and the industry as a whole.</div>
                </TickDiv>
                <TickDiv>
                  <div>Our aim is to make your transition to Centrik as quick and stress free as possible.</div>
                </TickDiv>
              </TextDiv>
            </ScrollAnimation>
          </Col>
          <Col md={12} lg={6} style={{ marginTop: phoneScreen && '80px', marginBottom: phoneScreen && '80px' }}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <ImageDiv right padding>
                <img src={Implementation} alt="image" />
              </ImageDiv>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
      <Gradient>
        <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
          <Container>
            <Row className={`justify-content-md-center`}>
              <Title>Centrik is trusted by industry professionals</Title>
              <CAADiv>
                {!phoneScreen && <img src={ASSILogo} alt="caa logo" style={{ width: '200px', marginRight: '40px' }} />}
                <CAATextWrapper>
                  <CAAInnerText>
                    “We’ve been able to tailor each of Centrik’s modules to suit our specific needs, which has made a number of our day-to-day operational processes more efficient: from managing our internal meetings and their subsequent actions, through to instantly pulling together comprehensive reports and audit information for analysis, a process that used to take an entire morning.”
                  </CAAInnerText>
                  <BoldText>Mark Baker Safety Development Manager</BoldText>
                  <div>
                    Air Safety Support International – a subsidiary company of the UK Civil Aviation Authority
                  </div>
                </CAATextWrapper>
              </CAADiv>
            </Row>
          </Container>
        </ScrollAnimation>
      </Gradient>
      <Container>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`} style={{ margin: phoneScreen && '60px 0 40px'}}>
          <Title>Centrik is part of the TrustFlight portfolio</Title>
          <Text>A range of digital workflow solutions that capture, store and share aviation data to further
          improve safety and efficiency, including:</Text>
        </Row>
        <Row className={`justify-content-md-center`}>
          <PortfolioCards />
        </Row>
        <Row className={`justify-content-md-center ${pageStyles.pageParentRow} ${styles.readyRow}`}>
          <Title>Ready to get started?</Title>
          <Text>Talk to our sales team with any questions you might have – discover how Centrik could enhance your operation.</Text>
          <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={150}>
            <Button
              href="/contact"
              variant="link"
              className={buttonStyles.ctaWhite}
              style={{ marginTop: '38px' }}
            >
              <Paperless>
                Ready to go paperless?
              </Paperless>
              <Touch>Get in Touch</Touch>
              <StyledArrow src={GoArrow} alt="arrow" className={styles.goArrow} />
            </Button>
          </ScrollAnimation>
        </Row>
      </Container>
    </Layout>
  )
};

export default Centrik;

export const query = graphql`
  query CentrikQuery {
    mainImage: file(relativePath: { eq: "Centrik/centrik-hero.svg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;