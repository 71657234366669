import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import DocumentIcon from '../../assets/Centrik/centrik-module-documents.svg';
import DashboardIcon from '../../assets/Centrik/centrik-module-dashboard.svg';
import AAMIcon from '../../assets/Centrik/centrik-module-asset-management.svg';
import SafetyIcon from '../../assets/Centrik/centrik-module-safety.svg';
import RiskIcon from '../../assets/Centrik/centrik-module-risk.svg';
import MeetingsIcon from '../../assets/Centrik/centrik-module-meetings.svg';
import ComplianceIcon from '../../assets/Centrik/centrik-module-compliance.svg';
import TrainingIcon from '../../assets/Centrik/centrik-module-training.svg';
import WorkflowIcon from '../../assets/Centrik/centrik-module-workflow.svg';
import Tick from '../../assets/icon-tick.svg';
import InfoHeader from './InfoHeader';

const dashboardCard = {
  title: 'Personalised Dashboard',
  key: 'dashboard',
  subTitle: 'Simple. Intuitive. Efficient.',
  icon: DashboardIcon,
  text: [
    { 
      header: `Integrated Oversight`,
      text: 'Every user’s dashboard provides total organisational oversight, both for individual tasks or required actions, as well as those of other members of the team and areas of the business. Full organisational oversight is available to all management, who have access to all individual dashboards, providing total transparency and accountability, helping to ensure ultra-efficient operation.',
    },
    { 
      header: `Efficient`,
      text: 'All Centrik users are provided with a login to their own personal, fully customisable, online dashboard which is accessible from any device, anywhere. Via their own tailored dashboard, you can provide third parties with access to all relevant documentation and data, as well as submit SMS reports or any additional operational information. Your external contractors and suppliers can feed into your Centrik system.',
    },
    {
      header: `Intuitive`,
      text: 'All information that’s accessible via a user’s dashboard relates to their individual job role within the business – users only have access to what is relevant to them. Via Centrik’s intuitive traffic light system, you can quickly and easily review and assess the operational status of all tasks, and instantly assign required actions if necessary.'
    },
  ]
}

const documentsCard = {
  title: 'Documents',
  key: 'document',
  subTitle: 'Access, update and manage in real-time',
  icon: DocumentIcon,
  points: [
    'Centrik’s Documents module gives you real, multi-department, multi-level document control with full traceability of readership and updates.',
    'Each user sees the documents relevant to their role, with immediate access from the home page. Whether you are a large airport with multiple department, or an AOC with little requirement for document compartmentalisation.',
    'Centrik is customised to make your documentation easy to manage and sort according to your requirements - in one place as a single source of truth.',
    'The Document module allows you to demonstrate to the regulator that you have effective document control.',
    'The Document Manager can send notifications to the user asking that they read specific documents.',
    'The instruction appears within the user’s individual dashboard - giving them an easy link to access the document, read it and confirm they have done so.',
    'The Document Manager can then review each document and see who has read it at an individual level - so there is complete transparency throughout, giving late readers nowhere to hide.',
    'Centrik’s Document module eliminates manual tracking to confirm whether your team have read documents, dramatically saving you time, and providing you with instant evidence for audits.'
  ],
}

const safetyCard = {
  title: 'Safety',
  key: 'safety',
  subTitle: 'CAA/EASA/ICAO compliant',
  icon: SafetyIcon,
  text: [
    {
      title: 'The Centrik Safety Module is an EASA-approved, SMS solution that allows you to:',
      points: [
        'Submit safety reports from any device – either via desktop or on the move with a tablet.',
        'From your tablet, instantly take, attach and file photographic evidence directly into your report.',
        'No internet connection? No problem! With Centrik, your fully compiled report will automatically sync and submit once you reconnect.',
        'You can even use Centrik’s voice recognition software to compile your report, no need to spend your days typing!',
        'Once submitted, your Safety Team can quickly classify and categorise every report, instantly attributing a risk score.',
        ' All relevant parties automatically receive instant feedback, directly from Centrik – removing the additional administrative burden on your Safety Team.',
        'Directly linked to your risk register.',
        'Instant publishing of mandatory reports to National Aviation Authorities in ECCAIRS or E5X/PDF/DASOR format.',
        'Assign remedial actions to team members.',
        'Then monitor the effectiveness of any mitigated actions.',
      ],
    },
    {
      title: 'Build a comprehensive database of all your safety reports, with concise risk heatmaps that clearly highlight what poses the most risk to your organisation.',
      points: [
        'See your risk scores change in real time as you mitigate those risks and close your safety reports.',
        'Generate KPIs based on your safety database – drill down into your operation in real time, instantly manipulating and configuring your performance data to streamline, improve and build on your organisation’s approach to safety reporting.',
      ],
    },
  ],
}

const riskCard = {
  title: 'Risk',
  key: 'risk',
  subTitle: 'A unified risk management system',
  icon: RiskIcon,
  points: [
    'With multi-hazard, multi-consequence risk assessments, you can condense your risk register or hazard log to make it much more manageable.',
    'Centrik’s risk assessment system allows you to break down each risk into individual hazards, with a full set of consequences matched to severity and likelihood. ',
    'You can then assign controls to each hazard, dynamically adjusting the risk score – plus of course you can assign those actions to individuals with full traceability of progress.',
    'All safety reports relating to a risk are also instantly available for further analysis.',
    'All risks feed into your risk register which provides instant information about when the next assessment is due (or if it’s overdue), the inherent risk for each item, your mitigation actions and your residual risk once actions are completed.',
    'See the impact of your real-time occurrence reports and findings against your risk register – it’s real-time holistic oversight of your risks.',
    'Sort your risk factors by severity to immediately demonstrate to a regulator your most significant risks and the actions to mitigate them – all supported by clear evidence, instantly available. ',
  ],
}

const complianceCard = {
  title: 'Compliance',
  key: 'compliance',
  subTitle: 'The hub for all of your quality and compliance oversight',
  icon: ComplianceIcon,
  text: [`The Compliance module is a centralised location for all of your internal and external compliance, allowing you to manage every aspect of your audit trail. It offers instant access to all current regulations pertaining to your operation, all constantly monitored and updated by the Centrik team.`,
  `If updates are required, you have the ability to cross-reference the regulations you have stored in your system with the latest versions. Any amendments or changes are clearly illustrated in a side-by-side comparison.`,
  `Centrik allows you to quickly and easily access your audit schedule, ensuring you maintain complete regulatory compliance for your operation. Utilising fully configurable audit checklists and templates that are linked directly to the regulations, you can categorise and classify any findings from your audit that need a response, then assign related actions to the appropriate user.`,
  `Centrik will then automatically alert each user of their required action and track their response.`,
  `Your Safety Team can assess audits, using Centrik’s interactive quality heatmaps and KPIs. Instantly see all findings and actions relating to the audit, so you build a picture of your quality and compliance in real time.`,
  `Perform audits on the move via tablet and instantly input audit data directly into the system. Centrik’s voice recognition option means you don’t even need to type. If you’re without an internet connection, offline functionality will still give you full access to all regulation checklists, allowing you to carry out your audit as normal and instantly file it into the system when back online.`,
  `No duplication of workload, no paper trails, no repeated inputting of data – save time and minimise the risk of human input error.`]
}

const ammCard = {
  title: 'Asset Management',
  key: 'amm',
  subTitle: 'Real-time management over all of your high value assets',
  icon: AAMIcon,
  text: [
    'The Equipment module is an asset management module, it enables you to create different types of equipment and manage them dynamically interacting with other Centrik modules.',
    'Within the module you can track calendar time, hours, cycles etc. and create maintenance schedules with periodic maintenance checks that have associated traffic light status displays which can inform key user dashboards.',
    'The Equipment module also has built in defect management that enables you to seamlessly distribute, review and sign off equipment defects.',
    'All equipment periodic checks, actions and defects can be assigned to staff giving ownership, visibility and accountability of this process. Compared to paper and spreadsheet management of equipment and assets, Centrik’s Equipment module saves an immense amount of time, duplication and enhances efficiency.',
  ]
}

const meetingsCard = {
  title: 'Meetings',
  key: 'meetings',
  subTitle: 'Highlight progress of actions and save hours of wasted time.',
  icon: MeetingsIcon,
  points: [
    'Centrik is the only tool you need to manage meetings within your business.',
    'Because everyone has their own Centrik dashboard, any action points or tasks can be instantly assigned, even as the meeting is taking place.',
    'Minutes can be input in real time and accessed by all – whether that staff member has attended the meeting or not.',
    'Like Centrik’s approach to safety, risk or quality, the Meetings module provides managers with full visibility of meeting minutes, actions and integrated metrics automatically pulled from the Safety and Compliance modules.',
    'Centrik’s Meetings module brings another level of efficiency to your operation. '
  ],
}

const trainingCard = {
  title: 'Training',
  key: 'training',
  subTitle: 'Your entire organisational training currency and records at your fingertips',
  icon: TrainingIcon,
  text: [
    {
      title: 'Whether in the office, hangar, cockpit or simulator, you have your entire organisational training currency and records at your fingertips:',
      points: [
        'Delivers a paperless, digital training solution ',
        'Tracking of staff currency, upcoming expiries and scheduled training',
        'Manager has oversight of all organisational training',
        'Staff see their own currency and training records',
        'Manage and issue company approvals',
        'Offline training via tablet ',
        'Ability to create line check, LIFUS and phased training courses',
        'Student management functionality',
        'Simple & intuitive training',
        'Staff training self-upload',
        'Easily configurable role based training ',
        'Multi department / organisation training areas',
      ],
    }
  ]
}

const workflowCard = {
  title: 'Workflows',
  key: 'workflows',
  subTitle: 'Simplify and streamline your processes',
  icon: WorkflowIcon,
  points: [
    'All processes are instantly available on each user’s home screen, so whenever a team member starts a new task, they can follow a clear, straightforward set of instructions. This ensures all activities are carried out according to approved procedures and can be clearly evidenced within an audit.',
    'Centrik also simplifies and automates workflows so that each individual sees the relevant points of each workflow, eliminating confusion and duplication.',
    'The Workflow module allows easy, effective management of change. Workflows can be linked to meetings – so for each change to a workflow, you can follow the approval trail back to the meeting where the decision was taken, review the notes and demonstrate to a regulator the objective proof.',
    'Centrik means your workflows are seamless, consistent and easy.',
    'All Centrik modules link in to the Workflows module, automating task allocation.',
    'If a safety report is filed which requires action, or a new aircraft is added to the AOC, a new person joins the business, or there is an emergency, the associated manager simply needs to record the details in the relevant module – and Centrik’s Workflows module takes care of the rest, dynamically and automatically assigning the relevant tasks to each person, straight to their dashboard.',
    'No more lever-arch files. No more confusing checklists. Just straightforward, dynamic task allocation with fully auditable evidence trail, all thanks to Centrik’s powerful Workflows module.',
  ],
}

const InfoSectionWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 rgba(36, 45, 65, 0.1);
  margin-bottom: ${({ last }) => (last ? '100px' : 0)};
  width: 100%;
`;

const HeaderSection = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: linear-gradient(to left, #03d4ff, #1e5bb5);
  padding: 24px 20px 20px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 12px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  opacity: 0.8;
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
`;

const ListSectionWrapper = styled.div`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 24px;
  width: 100%;
  @media screen and (max-width: 450px) {
    margin: 0 24px;
    padding-top: 24px;
  }
`;

const StyledTick = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
`;

const PointWrapper = styled.div`
  display: flex;
  width: calc(100% - 48px);
`;

const TextWrapper = styled.span`
  display: flex;
  width: calc(100% - 48px);
  line-height: 1.57;
  font-size: 16px;
  color: rgba(36, 45, 65, 0.7);
`;

const TitleTextWrapper = styled.div`
  width: calc(100% - 48px);
  line-height: 1.57;
  color: rgba(36, 45, 65, 0.7);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-top: ${({ index }) => (index ? '40px' : 'unset')};
`;

const PointText = styled.div`
  font-size: 16px;
  line-height: 1.57;
  color: rgba(36, 45, 65, 0.7);
  padding-bottom: 20px;
`;

const PointHeader = styled.div`
  font-size: 16px;
  line-height: 1.57;
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 20px;
  font-weight: 600;
`;

const HeaderWrapper = styled.div`
  width: calc(100% - 48px);
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0.3;
}
`;

const FadeWrapper = styled.div`
  animation: ${({ active }) => 
    active
      ? css`
        ${fadeOut} 2s
      `
      : css`
        ${fadeIn} 2s
      `
  };
`;

const InfoSection = ({ activeItem, last, active, phoneScreen }) => {
  const [currentCard, setCurrentCard] = useState(documentsCard);
  const cardArray = [documentsCard, safetyCard, riskCard, meetingsCard, complianceCard, workflowCard, trainingCard, dashboardCard, ammCard];
  const list = () => {
    if (currentCard.points) {
      return currentCard.points.map((point) => {
        return (
          <PointWrapper>
            <StyledTick src={Tick} alt="tick" />
            <PointText>{point}</PointText>
          </PointWrapper>
        )
      })
    } else if (currentCard.text) {
      return currentCard.text.map((line, index) => {
        if (line.title) {
          return (
            <TitleTextWrapper index={index === 1}>
              {line.title}
              <br />
              <br />
              {line.points.map((point) => {
              return (
                <PointWrapper>
                  <StyledTick src={Tick} alt="tick" />
                  <PointText>{point}</PointText>
                </PointWrapper>
              )})}
            </TitleTextWrapper>
          )
        } else if (line.header) {
          return (
          <HeaderWrapper>
            <PointHeader>{line.header}</PointHeader>
            <PointText>{line.text}</PointText>
          </HeaderWrapper>
          )
        } else {
          return (
            <TextWrapper>
              {line}
              <br />
              <br />
            </TextWrapper>
          )
        }
      })
    }
  };

  useEffect(() => {
    if (activeItem) {
      const newCard = cardArray.find((item) => item.key === activeItem);
      if (newCard) setCurrentCard(newCard);
    }
  }, [activeItem])

  return (
    <InfoSectionWrapper last={last}>
      {!phoneScreen && <InfoHeader card={currentCard} active={active} phoneScreen={phoneScreen} />}
      <ListSectionWrapper>
        {phoneScreen ? 
        (
          list()
        ) 
        : (
          <FadeWrapper active={active}>
            {list()}
          </FadeWrapper>
        )}
      </ListSectionWrapper>
    </InfoSectionWrapper>
  )
};

export default InfoSection;
