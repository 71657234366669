import React, { useEffect, useRef, useState } from 'react';
import { Accordion, AccordionCollapse, AccordionToggle, Card } from 'react-bootstrap';
import { Sticky, StickyContainer } from 'react-sticky';
import styled, { css, keyframes } from 'styled-components';
import DocumentIcon from '../../assets/Centrik/centrik-module-documents.svg';
import DashboardIcon from '../../assets/Centrik/centrik-module-dashboard.svg';
import AAMIcon from '../../assets/Centrik/centrik-module-asset-management.svg';
import SafetyIcon from '../../assets/Centrik/centrik-module-safety.svg';
import RiskIcon from '../../assets/Centrik/centrik-module-risk.svg';
import MeetingsIcon from '../../assets/Centrik/centrik-module-meetings.svg';
import ComplianceIcon from '../../assets/Centrik/centrik-module-compliance.svg';
import TrainingIcon from '../../assets/Centrik/centrik-module-training.svg';
import WorkflowIcon from '../../assets/Centrik/centrik-module-workflow.svg';
import InfoHeader from './InfoHeader';
import InfoSection from './InfoSection';
import styles from './InfoStyling.module.less';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 110px;
  padding: 0 10%;
  margin-bottom: 80px;
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    padding: 0;
  }
  @media screen and (max-width: 770px) {
    padding: 0;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  margin: 80px 0 20px;
  padding: 0 10%;
  text-align: center;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 150px;
  margin-top: 15px;
  flex-shrink: 0;
  position: sticky;
  top: 20px;
  @media screen and (max-width: 770px) {
    margin-right: 50px;
  }
`;

const StyledButton = styled.button`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${({ active  }) => (active ? '#1e5bb5' : '#242d41')};
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 20px;
  &:focus {
    outline: none;
  }
`;

const dashboardCard = {
  title: 'Personalised Dashboard',
  subTitle: 'Simple. Intuitive. Efficient.',
  icon: DashboardIcon,
}

const documentsCard = {
  title: 'Documents',
  subTitle: 'Access, update and manage in real-time',
  icon: DocumentIcon,
}

const safetyCard = {
  title: 'Safety',
  subTitle: 'CAA/EASA/ICAO compliant',
  icon: SafetyIcon,
}

const riskCard = {
  title: 'Risk',
  subTitle: 'A unified risk management system',
  icon: RiskIcon,
}

const complianceCard = {
  title: 'Compliance',
  subTitle: 'The hub for all of your quality and compliance oversight',
  icon: ComplianceIcon,
}

const ammCard = {
  title: 'Asset Management',
  subTitle: 'Real-time management over all of your high value assets',
  icon: AAMIcon,
}

const meetingsCard = {
  title: 'Meetings',
  subTitle: 'Highlight progress of actions and save hours of wasted time.',
  icon: MeetingsIcon,
}

const trainingCard = {
  title: 'Training',
  subTitle: 'Your entire organisational training currency and records at your fingertips',
  icon: TrainingIcon,
}

const workflowCard = {
  title: 'Workflows',
  subTitle: 'Simplify and streamline your processes',
  icon: WorkflowIcon,
}

const CentrikInfoSection = ({ phoneScreen }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [active, setActive] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevItem = usePrevious(activeItem);

  useEffect(() => {
    if (activeItem && prevItem && document.getElementById(activeItem)) {
      setTimeout(() => {
        document.getElementById(activeItem).scrollIntoView();
        window.scrollBy(0, -57);
      }, 300)
    }
  }, [activeItem]);

  useEffect(() => {
    setActiveItem('dashboard');
  }, []);

  const handleItemChange = (item) => {
    setActive(true);
    setTimeout(() => {
      setActiveItem(item);
      setActive(false);
    }, 225);
  };

  return (
    <>
      {phoneScreen ? (
        <div className={styles.accordion}>
          <Title>Centrik core modules include</Title>
          <Accordion>
            <AccordionToggle as={Card.Header} eventKey="0" id='dashboard' onClick={() => setActiveItem('dashboard')}><InfoHeader card={dashboardCard} /></AccordionToggle>
            <AccordionCollapse eventKey="0"><InfoSection phoneScreen={phoneScreen} activeItem="dashboard" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="1" id='document' onClick={() => setActiveItem('document')}><InfoHeader card={documentsCard} /></AccordionToggle>
            <AccordionCollapse eventKey="1"><InfoSection phoneScreen={phoneScreen} activeItem="document" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="2" id="safety" onClick={() => setActiveItem('safety')}><InfoHeader card={safetyCard} /></AccordionToggle>
            <AccordionCollapse eventKey="2"><InfoSection phoneScreen={phoneScreen} activeItem="safety" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="3" id="risk" onClick={() => setActiveItem('risk')}><InfoHeader card={riskCard} /></AccordionToggle>
            <AccordionCollapse eventKey="3"><InfoSection phoneScreen={phoneScreen} activeItem="risk" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="4" id="compliance" onClick={() => setActiveItem('compliance')}><InfoHeader card={complianceCard} /></AccordionToggle>
            <AccordionCollapse eventKey="4"><InfoSection phoneScreen={phoneScreen} activeItem="compliance" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="5" id="workflows" onClick={() => setActiveItem('workflows')}><InfoHeader card={workflowCard} /></AccordionToggle>
            <AccordionCollapse eventKey="5"><InfoSection phoneScreen={phoneScreen} activeItem="workflows" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="6" id="meetings" onClick={() => setActiveItem('meetings')}><InfoHeader card={meetingsCard} /></AccordionToggle>
            <AccordionCollapse eventKey="6"><InfoSection phoneScreen={phoneScreen} activeItem="meetings" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="7" id="amm" onClick={() => setActiveItem('amm')}><InfoHeader card={ammCard} /></AccordionToggle>
            <AccordionCollapse eventKey="7"><InfoSection phoneScreen={phoneScreen} activeItem="amm" /></AccordionCollapse>
            <AccordionToggle as={Card.Header} eventKey="8" id="training" onClick={() => setActiveItem('training')}><InfoHeader card={trainingCard} /></AccordionToggle>
            <AccordionCollapse eventKey="8"><InfoSection phoneScreen={phoneScreen} activeItem="training" /></AccordionCollapse>
          </Accordion>
        </div>
      ) : (
        <>
          <Title>Centrik core modules include</Title>
          <Wrapper>
            <ButtonColumn>
              <StyledButton type="button" onClick={() => handleItemChange('dashboard')} active={activeItem === 'dashboard'}>Personalised Dashboard</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('document')} active={activeItem === 'document'}>Documents (EFB)</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('safety')} active={activeItem === 'safety'}>Safety</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('risk')} active={activeItem === 'risk'}>Risk</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('compliance')} active={activeItem === 'compliance'}>Compliance</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('workflows')} active={activeItem === 'workflows'}>Workflows</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('meetings')} active={activeItem === 'meetings'}>Meetings</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('amm')} active={activeItem === 'amm'}>Asset Management</StyledButton>
              <StyledButton type="button" onClick={() => handleItemChange('training')} active={activeItem === 'training'}>Training</StyledButton>
            </ButtonColumn>
            <InfoSection activeItem={activeItem} active={active} phoneScreen={phoneScreen} />
          </Wrapper>
        </>
      )}
    </>
  );
};

export default CentrikInfoSection;
