import React from "react";
import styled from "styled-components";
import TechLogIcon from "../../assets/Centrik/graphic-product-tech-log.svg";
import MELIcon from "../../assets/Centrik/graphic-product-mel-manager.svg";
import LogbookIcon from "../../assets/Centrik/graphic-product-engine-log.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "gatsby";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

const techLogCard = {
  icon: TechLogIcon,
  link: "/techlog",
  title: "Tech Log",
  text: `Automated flight entry, intuitive defect recording and fully integrated with your existing scheduling and maintenance systems. Gain a real-time view of your aircraft\'s status and utilisation.`,
};

const melCard = {
  icon: MELIcon,
  link: "/mel-manager",
  title: "MEL Manager",
  text:
    "Issue and revise Minimum Equipment Lists (MELs) with ease for your entire fleet. Automate the creation, revision and publication of compliant MELs.",
};

const logbookCard = {
  icon: LogbookIcon,
  link: "/logbook",
  title: "Digital Engine Log",
  text:
    "The single-source of trusted flight and maintenance data associated with your aircraft. Standardised, secure and controlled by you.",
};

const StyledCard = styled.div`
  width: 345px;
  height: 390px;
  margin: 48px 22px;
  padding: 40px 32px 26px 33px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(36, 45, 65, 0.09);
  background-image: linear-gradient(to top, #f8fcff, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.7s;
  &:hover {
    box-shadow: 0 2px 25px 0 rgba(36, 45, 65, 0.09);
  }
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    flex-direction: unset;
    width: calc(100% - 44px);
    height: auto;
    margin: 10px 22px;
  }
  @media screen and (max-width: 770px) {
    flex-direction: unset;
    width: calc(100% - 44px);
    height: auto;
    margin: 10px 22px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

const StyledImage = styled.img`
  width: 160px;
  margin-bottom: 28px;
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    margin-right: 33px;
  }
  @media screen and (max-width: 770px) {
    margin-right: 33px;
  }
  @media screen and (max-width: 450px) {
    margin-right: 0;
  }
`;

const CardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #242d41;
  margin-bottom: 12px;
  @media screen and (max-width: 1025px) and (max-height: 770px) {
    margin-right: 33px;
  }
  @media screen and (max-width: 770px) {
    margin-right: 33px;
  }
  @media screen and (max-width: 450px) {
    text-align: center;
  }
`;

const CardText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(36, 45, 65, 0.7);
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PortfolioCards = () => {
  const card = (details) => {
    return (
      <Link to={details.link}>
        <StyledCard>
          <StyledImage src={details.icon} alt="icon" />
          <TextWrapper>
            <CardTitle>{details.title}</CardTitle>
            <CardText>{details.text}</CardText>
          </TextWrapper>
        </StyledCard>
      </Link>
    );
  };

  return (
    <CardWrapper>
      <Row className={`justify-content-md-center`}>
        <Col md={12} lg={12} xl={4} className={`justify-content-md-center`}>
          <ScrollAnimation
            animateOnce
            animateIn="fadeInLeft"
            offset={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {card(techLogCard)}
          </ScrollAnimation>
        </Col>
        <Col md={12} lg={12} xl={4} className={`justify-content-md-center`}>
          <ScrollAnimation
            animateOnce
            animateIn="fadeInUp"
            offset={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {card(melCard)}
          </ScrollAnimation>
        </Col>
        <Col md={12} lg={12} xl={4} className={`justify-content-md-center`}>
          <ScrollAnimation
            animateOnce
            animateIn="fadeInRight"
            offset={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {card(logbookCard)}
          </ScrollAnimation>
        </Col>
      </Row>
    </CardWrapper>
  );
};

export default PortfolioCards;
