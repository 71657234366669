import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import World from '../../assets/Landing/trustflight-global-scale.svg';
import GoArrow from '../../assets/icon-arrow-white.svg';
import Tick from '../../assets/tick-neon.svg';
import styles from './globalScale.module.less';
import { Link } from 'gatsby';

const Background = styled.div`
  background-image: linear-gradient(119deg, #010306, #083976);
  height: auto;
  padding: 80px 0;
  @media screen and (max-width: 450px) {
    padding: 80px 10px;
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  background-image: url(${World});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
`;

const GlobalText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #03d4ff;
  margin-bottom: 16px;
`;

const TrustText = styled.div`
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 40px;
`;

const MainText = styled.div`
  font-size: 18px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 60px;
  max-width: 85%;
`;

const NumberText = styled.div`
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #03d4ff;
  margin-bottom: 16px;
`;

const WhiteText = styled.div`
  font-size: 18px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.9);
  @media screen and (max-width: 450px) {
    margin-bottom: 30px;
  }
`;

const GetInTouchBar = styled.div`
  width: 100%;
  height: 76px;
  background-image: linear-gradient(to left, #03d4ff, #1e5bb5);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TransformText = styled.span`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  padding-right: 40px;
  @media screen and (max-width: 450px) {
    text-align: center;
    padding-right: 0;
    padding-bottom: 10px;
  }
`;

const GetInTouchText = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 13px;
  }
  &:hover {
    img {
      transform: translateX(3px);
      transition: all 0.1s;
    }
  }
`;

const TickDiv = styled.div`
  margin-bottom: ${({ bottom }) => (bottom ? '80px' : '20px')};
  display: flex;
  align-items: flex-start;
  img {
    width: 24px;
    margin-right: 8px;
  }
  div {
    font-size: 18px;
    line-height: 1.56;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const GlobalScale = ({ centrik }) => {
  return (
    <>
      <Background>
        <Container>
          <Row className={styles.mainRow}>
            <Col sm={12} lg={6} className={styles.textCol}>
              <GlobalText>GLOBAL SCALE</GlobalText>
              <TrustText>Trusted by the aviation industry</TrustText>
              {centrik ? (
                <>
                  <TickDiv>
                    <img src={Tick} alt="tick" />
                    <div>Developed by aviation professionals</div>
                  </TickDiv>
                  <TickDiv bottom>
                    <img src={Tick} alt="tick" />
                    <div>Used for more than a decade by AOCs, airports, training providers, maintenance facilities, regulators, UAS and defence clients</div>
                  </TickDiv>
                </>
              ) : <MainText>With customers in every continent, our global team understands your unique challenges. Local regulatory expertise, 24/7 product support and an unparalleled commitment to our customers are just a few of the ways we have grown to become a trusted partner for the aviation industry.</MainText>}
              <Row className={styles.row}>
                {/* // <>
                //   <Col>
                //     <NumberText>10,000+</NumberText>
                //     <WhiteText>Aircraft managed</WhiteText>
                //   </Col>
                //   <Col>
                //     <NumberText>250+</NumberText>
                //     <WhiteText>Clients worldwide</WhiteText>
                //   </Col>
                //   <Col>
                //     <NumberText>59,000+</NumberText>
                //     <WhiteText>Global users</WhiteText>
                //   </Col>
                // </> */}
                <>
                  <Col>
                    <NumberText>70,000+</NumberText>
                    <WhiteText>Users</WhiteText>
                  </Col>
                  <Col>
                    <NumberText>260+</NumberText>
                    <WhiteText>Customers</WhiteText>
                  </Col>
                  <Col>
                    <NumberText>50+</NumberText>
                    <WhiteText>Countries operated in</WhiteText>
                  </Col>
                </>
              </Row>
            </Col>
            <Col sm={12} lg={6} className={styles.imageCol}>
              <ImageWrapper>
                <StyledImg  src={World} />
              </ImageWrapper>
            </Col>
          </Row>
        </Container>
      </Background>
      {/* <GetInTouchBar>
        <TransformText>More than 250 clients worldwide use Centrik to effectively manage their operations more easily and efficiently</TransformText>
        <Link to="/contact">
          <GetInTouchText>Get in Touch <img src={GoArrow} alt="arrow" /></GetInTouchText>
        </Link>
      </GetInTouchBar> */}
    </>
  )
};

export default GlobalScale;
